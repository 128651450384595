<template>
	<div class="header">
		<div class="main">
			<img class="logo" src="../../assets/icon/logo.png" @click="$router.replace('/Home')" />
			<div class="guide">
				<div v-for="(val, key) in guide" :key="key" :class="`item ${current == val.categoryId ? 'active' : ''}`"
					@click="$router.push(`/GoodsList?cateId=${val.categoryId}`)">
					<span class="name">{{ val.categoryName }}</span>
					<div :class="`bar big`"></div>
					<div class="slideDown">
						<div class="m">
							<template v-for="v in val.sons">
								<img class="cateCover" :src="v.categoryCover" />
								<div class="series">
									<div class="title" @click.stop="$router.push(`/GoodsList?cateId=${v.categoryId}`)">
										{{ v.categoryName }}
									</div>
									<div class="item" v-for="(item, key) in v.sons" :key="key"
										@click.stop="$router.push(`/GoodsList?cateId=${item.categoryId}`)">
										{{ item.categoryName }}
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div class="func">
				<div class="lang">
					<span class="currentLang">
						<el-dropdown @command="selectLang">
							<span class="el-dropdown-link">
								{{ getLangName() }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="es-ES"><span
										:class="$store.state.language == 'es-ES' ? 'currentLang' : ''">Español</span>
								</el-dropdown-item>
								<el-dropdown-item command="en-GB"><span
										:class="$store.state.language == 'en-GB' ? 'currentLang' : ''">English</span>
								</el-dropdown-item>
								<el-dropdown-item command="zh-CN"><span
										:class="$store.state.language == 'zh-CN' ? 'currentLang' : ''">Chinese</span>
								</el-dropdown-item>
								<!-- <el-dropdown-item command="zh-CN"><span :class="$store.state.language == 'zh-CN'?'currentLang':''">中文</span></el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>
					</span>
					<!-- <i class="el-icon-arrow-down"></i> -->
				</div>
				<!-- 搜索 -->
				<img class="item" @click="show.search = true" src="../../assets/icon/search.png" />
				<!-- 我的 -->
				<img class="item" @click="$store.state.isLogin ? (show.mine = true) : (show.login = true)"
					src="../../assets/icon/personal.png" />
				<!-- 收藏 -->
				<img class="item" @click="$store.state.isLogin ? $router.push('/Collect') : (show.login = true)"
					src="../../assets/icon/like.png" />
				<!-- 篮子 -->
				<el-popover v-if='cartList.length!=0 && $store.state.isLogin' placement='bottom-end' width="300"
					trigger="hover">
					<span slot="reference" class="item"
						@click="$store.state.isLogin ? $router.push('/Cart') : (show.login = true)">
						<img class="regular" style="display: inline-block;width: 18px;"
							src="../../assets/icon/basket.png" />
						<div class="cartNum" v-if="cart > 0">{{ cart }}</div>
					</span>
					<div class="scroll">
						<div class="cart-item" v-for="(item,index) in cartList" :key='index'>
							<img :src="item.cover" width="68px" height="92px" alt="">
							<div>
								<span class="cart-name">{{item.spuName}}</span>
								<span class="cart-num"><span
										style="margin-right: 10px;">{{$t("goodDetail.num")}}</span>{{item.num}}</span>
								<span class="cart-price">€{{item.price*item.num}}</span>
							</div>
						</div>
					</div>
					<div class="footer-btn">
						<p>
							<span class="cart-total">Total</span>
							<span class="cart-total-price">€{{totalPrice||0.00}}</span>
						</p>
						<div class="cart-btn"
							@click="$store.state.isLogin ? $router.push('/Cart') : (show.login = true)">
							{{$t("tip.check_cart")}}
						</div>
					</div>
				</el-popover>
				<span v-else slot="reference" class="item"
					@click="$store.state.isLogin ? $router.push('/Cart') : (show.login = true)">
					<img class="regular" style="display: inline-block;width: 18px;"
						src="../../assets/icon/basket.png" />
					<div class="cartNum" v-if="cart > 0">{{ cart }}</div>
				</span>
			</div>
			<div class="bottomBar"></div>
		</div>
		<!-- 搜索的抽屉 -->
		<el-drawer append-to-body size="400px" :visible.sync="show.search" direction="ttb">
			<div class="drawerMain">
				<!-- <div class="sex">
					<el-radio v-model="search.sex" :label="1">男</el-radio>
					<el-radio v-model="search.sex" :label="0">女</el-radio>
				</div> -->
				<div class="input">
					<el-input :placeholder="$t('nav.searchHolder')" @keyup.enter.native="goSearch"
						v-model="search.value"></el-input>
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</el-drawer>
		<!-- "我的"抽屉 -->
		<el-drawer class="r2l" append-to-body size="400px" :visible.sync="show.mine" direction="rtl">
			<div class="mineLink">
				<div class="item" @click="$router.push('/OrderList')">
					<span>{{ $t("userTab.order") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="item" @click="$router.push('/Information')">
					<span>{{ $t("userTab.setting") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
				<div class="item" @click="$router.push('/Address')">
					<span>{{ $t("userTab.address") }}</span>
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
			<div class="quit" @click="$bus.$emit('quit')">{{ $t("login.logoutTitle") }}</div>
		</el-drawer>
		<!-- 登录注册抽屉 -->
		<el-drawer class="r2l" :wrapperClosable="false" append-to-body size="520px" :visible.sync="show.login"
			direction="rtl">
			<Login v-if="show.login"></Login>
		</el-drawer>
	</div>
</template>

<script>
	import Api from "../../api/request.js";
	import Login from "./login.vue";
	export default {
		name: "Header",
		components: {
			Login,
		},
		watch: {
			$route(res) {
				this.show.search = false;
				this.show.mine = false;
				this.show.login = false;
				if (res.path == "/GoodsList") {
					this.current = res.query.cateId;
				} else {
					this.current = "";
				}
			},
		},
		data() {
			return {
				show: {
					search: false,
					mine: false,
					login: false,
				},
				search: {
					sex: 1,
					value: "",
				},
				cart: 0,
				cartList: [],
				guide: [],
				current: "",
			};
		},
		computed: {
			totalPrice() {
				var price = 0;
				this.cartList.forEach(item => {
					price += Number(item.price) * Number(item.num);
				})
				return price;
			}
		},
		mounted() {
			this.$bus.$on("login", (res) => {
				this.show.login = true;
			});
			this.$bus.$on("getCartNum", (res) => {
				this.getCartNum();
			});
			this.getCates();
			this.getCartNum();
		},
		methods: {
			getCates() {
				this.guide = this.$store.state.cateList;
			},
			getCartNum() {
				if (this.$store.state.isLogin) {
					Api.Cart.list({}).then((res) => {
						this.cartList = res.data.data.list;
						this.cart = res.data.data.total;
					});
				}
			},
			goSearch() {
				this.show.search = false;
				this.$router.push(`/Search?key=${this.search.value}`);
			},
			selectLang(res) {
				this.$i18n.locale = res;
				this.$store.state.language = res;
			},
			getLangName() {
				switch (this.$store.state.language) {
					case "zh-CN":
						return "中文";
						break;
					case "en-GB":
						return "English";
						break;
					case "es-ES":
						return "Español";
						break;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.header {
		height: 134px;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 99;

		.main {
			height: 100%;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.logo {
				position: absolute;
				width: 200px;
				height: 62px;
				top: 36px;
				left: 40px;
				cursor: pointer;
			}

			.guide {
				width: 900px;
				display: flex;

				// justify-content: space-between;
				&>.item {
					position: relative;
					height: 134px;
					padding: 0 20px;

					.slideDown {
						position: fixed;
						left: 0;
						right: 0;
						top: 134px;
						min-height: 100px;
						background: #fff;
						display: none;
						z-index: 9;
						box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.2);

						.m {
							display: flex;
							justify-content: center;
							padding: 40px 150px 80px;

							.cateCover {
								width: 300px;
								display: block;
								margin-right: 20px;
								object-fit: cover;
								background: #ccc;
							}

							.series {
								width: 230px;

								.title {
									font-size: 14px;
									font-weight: bold;
									color: #000000;
									line-height: 20px;
									cursor: pointer;
								}

								.item {
									font-size: 14px;
									color: #000000;
									line-height: 20px;
									margin-top: 17px;
									cursor: pointer;

									&:hover {
										font-size: 16px;
										font-weight: bold;
									}
								}
							}
						}
					}

					&:hover {
						.slideDown {
							display: block;
						}
					}

					.name {
						font-size: 20px;
						line-height: 134px;
						font-family: GillSans;
						color: #000000;
					}

					.bar {
						position: absolute;
						height: 3px;
						background: #000000;
						bottom: 30px;
						opacity: 0;
						left: 70px;
						right: 70px;
						transition: all 0.3s;
					}

					&:hover,
					&.active {
						.bar {
							opacity: 1;

							&.big {
								left: 22px;
								right: 22px;
							}

							&.small {
								left: 5px;
								right: 5px;
							}
						}
					}
				}
			}

			.func {
				position: absolute;
				display: flex;
				top: 0;
				bottom: 0;
				right: 40px;
				align-items: center;

				.item {
					width: 18px;
					height: 18px;
					margin-left: 40px;
					cursor: pointer;
					position: relative;

					.cartNum {
						position: absolute;
						width: 16px;
						height: 16px;
						background: #000;
						border-radius: 50%;
						top: -8px;
						right: -8px;
						text-align: center;
						color: #fff;
						font-size: 12px;
						line-height: 16px;
					}
				}

				.lang {
					cursor: pointer;
					font-size: 13px;
					font-weight: 600;
					color: #000000;

					&>span {
						font-family: GillSans;
					}

					&>i {
						font-size: 10px;
						font-weight: bold;
						margin-left: 12px;
						color: #000;
					}
				}
			}

			.bottomBar {
				position: absolute;
				bottom: 0;
				left: 46px;
				right: 46px;
				height: 1px;
				background: #000;
			}
		}
	}

	/deep/.el-tabs__item:focus.is-active.is-focus {
		box-shadow: none;
	}

	/deep/:focus {
		outline: 0;
	}

	/deep/.el-drawer {
		&.ttb {
			background: #000;

			.el-drawer__header {
				.el-drawer__close-btn {
					color: #fff;
					font-weight: bold !important;
				}
			}
		}

		&.rtl {
			.el-drawer__header {
				margin-bottom: 0px;

				.el-drawer__close-btn {
					color: #000;
					font-weight: bold !important;
				}
			}
		}

		.el-radio__input {
			.el-radio__inner {
				background: #000;
			}

			&.is-checked {
				.el-radio__inner {
					border-color: #fff !important;

					&::after {
						width: 6px;
						height: 6px;
					}
				}

				&+.el-radio__label {
					color: #fff;
				}
			}
		}
	}

	.drawerMain {
		width: 750px;
		margin: 0 auto;

		.input {
			height: 56px;
			display: flex;
			align-items: center;
			color: #fff;
			border-bottom: 2px solid #fff;
			margin-top: 45px;

			/deep/.el-input__inner {
				flex: 1;
				color: #fff;
				background: none;
				border: none;
			}

			&>i {
				margin-right: 13px;
				font-weight: bold;
			}
		}
	}

	.mineLink {
		padding: 40px;

		.item {
			height: 68px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&>span {
				font-size: 14px;
			}

			&>i {
				font-size: 13px;
				font-weight: bolder;
			}

			border-bottom: 1px solid #d9d9d9;
			cursor: pointer;
		}
	}

	.r2l {
		.el-drawer__body {
			position: relative;

			.quit {
				position: absolute;
				bottom: 40px;
				left: 40px;
				right: 40px;
				height: 46px;
				line-height: 46px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #123178;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: #123178;
				cursor: pointer;

				&:hover {
					background-color: #efefef;
				}
			}
		}

		/deep/.el-dialog__close {
			font-size: 25px;

			&:hover {
				color: red;
			}
		}
	}

	.currentLang {
		font-weight: bold;
	}

	.el-dropdown-menu {
		.el-dropdown-menu__item {
			&:hover {
				background: rgba(0, 0, 0, 0.1);
				color: #000;
			}
		}
	}

	.cart-btn {
		width: 100%;
		height: 40px;
		border-radius: 6px;
		border: 1px solid #123178;
		font-size: 14px;
		font-weight: 600;
		color: #123178;
		line-height: 40px;
		text-align: center;
		cursor: pointer;
		margin: 15px auto;
	}

	.footer-btn {
		padding-top: 20px;

		>p {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: bold;
		}
	}

	.scroll {
		width: 100%;
		max-height: 35vh;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		padding-top: 20px;

		.cart-item {
			width: 100%;
			padding-bottom: 14px;
			border-bottom: 1px solid #ccc;
			display: flex;

			>div {
				width: calc(100% - 83px);
				display: flex;
				flex-direction: column;
				margin-left: 15px;

				.cart-num {
					margin: 15px 0;
				}

				.cart-name {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: bold;
				}

				.cart-price {
					font-size: 14px;
					font-weight: bold;
				}
			}
		}

		.cart-item+.cart-item {
			padding-top: 15px;
		}
	}

	@media screen and (max-width: 1400px) {
		.header {
			.main {
				.logo {
					width: 150px;
					height: 45px;
				}

				.guide {
					width: 800px;

					.item {
						padding: 0 10px;
					}
				}

				.func {
					.item {
						margin-left: 25px;
					}
				}
			}
		}
	}
</style>
